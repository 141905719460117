import React from "react";
import { useTranslations } from "@/i18n/utils.ts";
import { usePrayerTimes } from "@/hooks/usePrayerTimes.ts";

const prayerIcons = {
  fajr: "mingcute--moon-stars-line",
  sunrise: "mingcute--sun-fog-line",
  dhuhr: "mingcute--sun-line",
  asr: "mingcute--sun-cloudy-line",
  maghrib: "mingcute--sunset-line",
  isha: "mingcute--moon-line",
};
type PrayerTimesProps = {
  lang: "ar" | "en";
};

const PrayerTimes: React.FC<PrayerTimesProps> = ({ lang }) => {
  const t = useTranslations(lang);

  const prayersTimes = usePrayerTimes();

  const isNextPrayer = (prayerTime: string) => {
    const [hours, minutes] = prayerTime.split(":").map(Number);

    const now = new Date();

    const prayerDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
    );

    return prayerDate.getTime() > now.getTime();
  };

  if (prayersTimes.loading || !prayersTimes.value) {
    return (
      <div className="flex w-full items-center justify-center gap-4 bg-base-300 px-4 py-4 max-md:py-2 max-sm:gap-2 max-sm:px-2 lg:rounded-lg max-sm:flex-wrap max-[300px]:flex-col">
        {Array(6)
          .fill(null)
          .map((_, idx) => (
            <div
              key={`prayer_time_placeholder_${idx}`}
              className={
                "flex w-1/6 max-[300px]:w-full flex-col items-center gap-2 rounded-lg border border-primary p-3 text-primary max-sm:w-[calc(50%-8px)] max-sm:h-10 max-sm:flex-row"
              }
            >
              <div
                className={
                  "h-8 w-8 max-sm:h-6 max-sm:min-h-6 max-sm:w-6 max-sm:min-w-6 skeleton !bg-base-200"
                }
              ></div>
              <div className="skeleton !bg-base-200 w-24 h-7 rounded-lg"></div>
              <div className="skeleton !bg-base-200 w-14 h-6 rounded-lg"></div>
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className="flex w-full items-center justify-center gap-4 bg-base-300 px-4 py-4 max-md:py-2 max-sm:gap-2 max-sm:px-1 lg:rounded-lg max-sm:flex-wrap max-[300px]:flex-col">
      {Object.entries(prayersTimes.value).map(([name, time]) => (
        <div
          key={name}
          className={
            isNextPrayer(time)
              ? "flex w-1/6 max-[300px]:w-full flex-col items-center gap-2 rounded-lg border border-primary bg-primary p-3 text-base-100 max-sm:w-[calc(50%-8px)] max-sm:h-10 max-sm:flex-row"
              : "flex w-1/6 max-[300px]:w-full flex-col items-center gap-2 rounded-lg border border-primary p-3 text-primary max-sm:w-[calc(50%-8px)] max-sm:h-10 max-sm:flex-row"
          }
        >
          <span
            className={`icon-[${prayerIcons[name as keyof object]}] h-8 w-8 max-sm:h-6 max-sm:min-h-6 max-sm:w-6 max-sm:min-w-6`}
          ></span>
          <h1 className="text-xl font-semibold max-lg:text-sm">
            {t(("prayers." + name.toLowerCase()) as any)}
          </h1>
          <p className="font-semibold max-md:text-xs max-sm:ml-auto rtl:max-sm:mr-auto rtl:max-sm:ml-0">
            <bdi>{time}</bdi>
          </p>
        </div>
      ))}
    </div>
  );
};

export default PrayerTimes;
